import React from "react";
import InfluencerPage from "./InfluencerPage";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styled from "styled-components";
import "./App.css";

const StyledHeader = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  background: #ffffff;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 100px;
  align-items: center;
  height: 100%;
`;

const Logo = styled.h1`
  font-size: 36px;
  font-family: Avenir;
  font-weight: normal;
`;

const Header = function () {
  return (
    <StyledHeader>
      <HeaderContainer>
        <Logo>Mulu</Logo>
        <p>Sign In</p>
      </HeaderContainer>
    </StyledHeader>
  );
};

const AppContainer = function () {
  return (
    <>
      <Header />
      <InfluencerPage />
    </>
  );
};

function App() {
  // TODO: move to config file
  const stripePromise = loadStripe(
    "pk_test_AFVe9WvWUS5PPn0zIZgRt4XA004iTeYVXq"
  );

  return (
    <Elements stripe={stripePromise}>
      <AppContainer />
    </Elements>
  );
}

export default App;
