import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Class from "./components/Class";
import { useStripe } from "@stripe/react-stripe-js";

const Container = styled.div`
  padding: 100px;
  width: 1000px;
  margin: auto;
  overflow-y: scroll;
`;

const StyledAvatar = styled.img`
  height: 150px;
  width: 150px;
  border-radius: 100px;
`;

const ProfileBar = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const UserInfoContainer = styled.div`
  /* display: flex; */
  /* height: 100%; */
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 50px;
  width: 100%;
`;

const StyledName = styled.div`
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  color: #444444;
  padding-bottom: 10px;
`;

const StyledHandle = styled.div`
  font-family: PT Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #444444;
`;

const StyledBio = styled.div`
  color: #000000;
  font-size: 16px;
  padding-top: 40px;
  width: 100%;
`;

const LiveClassesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: space-between;
`;

const url =
  process.env.NODE_ENV == "production"
    ? "https://api.getmulu.com"
    : "http://localhost:4000";

const InfluencerPage = () => {
  //   const { id } = useParams();
  const [user, setUser] = useState(undefined);
  const stripe = useStripe();

  useEffect(() => {
    const fetchData = async () => {
      // TODO: grab from url
      const resp = await (await fetch(`${url}/taycokes`)).json();
      setUser(resp);
    };
    fetchData();
  }, []);

  if (!user) {
    return <p>Loading...</p>;
  }

  return (
    <Container>
      <ProfileBar>
        <StyledAvatar src={user.imageUrl} />
        <UserInfoContainer>
          <StyledName>{user.name}</StyledName>
          <StyledHandle>@{user.username}</StyledHandle>
          <StyledBio>{user.bio}</StyledBio>
        </UserInfoContainer>
      </ProfileBar>
      <hr style={{ marginTop: "50px" }} />
      <h3>Upcoming Live Classes</h3>
      <LiveClassesContainer>
        {user.fitclass.map((c) => {
          return (
            <Class
              key={c.id}
              classData={c}
              onBook={() => {
                console.log("book class");
              }}
            />
          );
        })}
      </LiveClassesContainer>
    </Container>
  );
};

export default InfluencerPage;
