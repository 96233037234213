import React, { useState, useCallback } from "react";
import styled from "styled-components";
import EventIcon from "@material-ui/icons/Event";
import GroupIcon from "@material-ui/icons/Group";
import { useStripe } from "@stripe/react-stripe-js";

const ClassContainer = styled.div`
  height: 450px;
  width: 450px;
  margin: 20px;
  background-color: #ffffff;
  position: relative;
`;

const ClassImage = styled.img`
  height: 250px;
  width: 100%;
`;

const ClassInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px 20px;
`;

const ClassTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  font-family: PT Sans;
`;

const ClassPrice = styled.div`
  color: #9060eb;
  font-weight: bold;
  font-size: 24px;
`;
const ClassDescription = styled.div`
  padding: 0px 10px;
`;

const ClassDetails = styled.div`
  position: absolute;
  bottom: 10px;
  padding: 0px 10px;
`;

const ClassDatum = styled.div`
  display: inline;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.44);
  vertical-align: middle;
  padding-left: 10px;
`;

const StyledBookButton = styled.button`
  background: #9060eb;
  border-radius: 100px;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  width: 120px;
  height: 30px;
`;

const ButtonContainer = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const LearnMoreText = styled.div`
  color: #9060eb;
  font-size: 14px;
  font-weight: normal;
  padding-top: 5px;
  text-align: center;
`;

export default function Class({ onBook, classData }) {
  const stripe = useStripe();

  const {
    id,
    title,
    description,
    priceCents,
    startTime,
    lengthMin,
    imageUrl,
  } = classData;
  console.log("classData: ", classData);

  const [checkoutError, setCheckoutError] = useState(undefined);

  const bookCallback = useCallback(async () => {
    console.log("starting checkout");
    const resp = await fetch(`http://localhost:4000/class/${id}/checkout`, {
      method: "POST",
    });

    const checkoutSession = await resp.json();
    console.log(checkoutSession);
    const { error } = await stripe.redirectToCheckout({
      sessionId: checkoutSession.id,
    });
    console.log("checkout error: ", error);
    setCheckoutError(error);
  }, [id, stripe]);

  return (
    <ClassContainer>
      <ClassImage src={imageUrl} />
      <ClassInfoHeader>
        <ClassTitle>{title}</ClassTitle>
        <ClassPrice>${priceCents / 100}</ClassPrice>
      </ClassInfoHeader>
      <ClassDescription>{description}</ClassDescription>
      <ClassDetails>
        <div>
          <EventIcon
            style={{ verticalAlign: "middle", color: "rgba(0,0,0,0.44)" }}
          />
          <ClassDatum>{startTime}</ClassDatum>
        </div>
        <div>
          <GroupIcon
            style={{ verticalAlign: "middle", color: "rgba(0,0,0,0.44)" }}
          />
          <ClassDatum>12 people signed up</ClassDatum>
        </div>
      </ClassDetails>
      <ButtonContainer>
        <StyledBookButton
          onClick={async () => {
            console.log("book class");
            await bookCallback();
          }}
        >
          Book me
        </StyledBookButton>
        <LearnMoreText>
          or <span style={{ textDecoration: "underline" }}>learn more</span>
        </LearnMoreText>
      </ButtonContainer>
    </ClassContainer>
  );
}
